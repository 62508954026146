import React, { useState, useEffect } from 'react';
import AzureFunction from './APIs/AzureFunction';
import { Typography, Grid, CircularProgress, Stack } from '@mui/material';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { Link as RouterLink } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

//Used to map status numbers to correct name in iBinder platform
const dict = {
  0: 'Mottaget',

  1: 'Pågående',

  2: 'Pågående',

  3: 'Avslutat',
};

//retrieve issues from ibinder platform
const SentForms = ({ accountInfo, token }) => {
  const [load, setLoad] = useState(false);
  const [issues, setIssues] = useState([]);
  const [dense, setDense] = React.useState(false);

  const useIdToken = () => {
    const { instance, accounts } = useMsal();
    const [idToken, setIdToken] = useState(null);

    if (accounts.length > 0) {
      const request = {
        scopes: ['openid'],
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(request)
        .then((response) => {
          setIdToken(response.idToken);
        })
        .catch((error) => {
          // acquireTokenSilent can fail for a number of reasons, fallback to interaction
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenPopup(request).then((response) => {
              setIdToken(response.idToken);
            });
          }
        });
    }

    return idToken;
  };
  //const test = useIdToken()

  useEffect(async () => {
    const response = await AzureFunction.get(
      'api/RetrieveCasesStatusesAsync?code=KZaGiZkqK7U5lVRgBSrDeP5KKy/ZOTVOwcspLrrY5XfXQT7PoNgQWw==',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        params: {
          CustomerId: 'malmostad',
          UserId: accountInfo.username,
        },
      }
    );

    setIssues(response.data);
    setLoad(true);
  }, []);

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //Theme used to do text responsive
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  //Sort issues by status code
  const issuesList = issues
    .sort((a, b) => (a.Status > b.Status ? 1 : -1))
    .map((item) => {
      return (
        <Box sx={{ flexGrow: 1, maxWidth: 'auto' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Demo>
                <List dense={dense}>
                  <ListItem
                    key={item.Id}
                    secondaryAction={
                      <RouterLink
                        to={{ pathname: '/view/reports/details' }}
                        state={item}>
                        <IconButton edge="end" aria-label="forward">
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </RouterLink>
                    }>
                    <ListItemText
                      primary={item.Title}
                      secondary={'Status: ' + dict[item.Status]}
                    />
                  </ListItem>
                </List>
              </Demo>
            </Grid>
          </Grid>
          <Divider variant="middle" />
        </Box>
      );
    });

  return (
    <div>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start">
        <ThemeProvider theme={theme}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              sx={{ marginLeft: 1, marginTop: 1 }}
              align="left"
              style={{ color: '#314F6F', fontFamily: 'sans-serif' }}>
              Tidigare felanmälningar
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              gutterBottom
              component="div"
              align="left"
              sx={{ marginLeft: 1, marginTop: 1 }}
              style={{ fontFamily: 'sans-serif' }}>
              Se status på tidigare felanmälan
            </Typography>
          </Grid>
        </ThemeProvider>
      </Grid>
      {load === false && (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}>
          <CircularProgress />
        </Stack>
      )}
      {issuesList}
    </div>
  );
};

export default SentForms;
