import React, { useState, useEffect } from 'react';
import AzureFunction from './APIs/AzureFunction';
import {
  Box,
  TextField,
  Grid,
  Autocomplete,
  MenuItem,
  Typography,
  Button,
  Snackbar,
  Stack,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/material/styles';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

//MenuProps is used to minimize the dropdowns so that they don't take the hole screen
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled('input')({
  display: 'none',
});

//categories that is used in the issue report
const categories = [
  'El och belysning',
  'Lampbyte',
  'Lås',
  'Larm/Passage',
  'VVS- vattenrör och avlopp',
  'Stopp i avloppet',
  'Kran/duschmunstycke',
  'Dörr',
  'Dörrautomatik',
  'Kökselement',
  'Vitvaror kök',
  'Storköksmaskin',
  'Vitvaror tvättstuga',
  'Persienner',
  'Översvämning',
  'Övrigt',
  'Montering',
  'Demontering',
  'Reparation',
  'Iordningställa lokalen',
  'Transport',
  'Besiktning',
];
//Alert used for the snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CreateForm = ({ facilities, accountInfo, token }) => {
  //Initial values used to create state based on model in azure functions
  const initialFValues = {
    Title: '',
    FacilityName: '',
    FacilityAdress: '',
    LandRegistryReference: '',
    CostCenters: '',
    Floor: '',
    RoomNumber: '',
    EntranceCode: '',
    Description: '',
    IsApartment: '',
    Errors: [],
    ContactPerson: accountInfo.name,
    PhoneNumber: '',
    Email: accountInfo.username,
    ImgData: '',
  };

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const [values, setValues] = useState(initialFValues);
  const [image, setImage] = useState('Ingen bild vald');
  const [success, setSuccess] = useState(null);
  const [open, setOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = React.useState([]);
  const [optionAdress, setOptionAdress] = React.useState(null);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleErrors = (prop) => (event) => {
    const tempArr = [event.target.value];
    setValues({ ...values, [prop]: tempArr });
  };
  const onChange = (e) => {
    //When file is selected
    e.preventDefault();
    const files = e.target.files;
    const file = files[0];
    setImage(file.name); //Used for file name on screen

    getBase64(file);
  };
  const onChangeAdress = (newValue) => {
    //When adress is selected
    //change dropdown depending on adress value
    //Filter so you only get objects with selected Adress
    const dropdown = facilities.filter(
      (facilities) => facilities.FacilityAdress === newValue
    );

    setDropdownValue({ dropdown }.dropdown);
    setValues({
      ...values,
      FacilityAdress: newValue,
      LandRegistryReference: { dropdown }.dropdown[0].LandRegistryReference,
    });
  };
  //Set base64 string of image to state values.ImgData
  const onLoad = (fileString) => {
    setValues({ ...values, ImgData: fileString });
  };

  const getBase64 = (file) => {
    //transform selected file to base64
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //const jsonData = JSON.stringify(values)
    const dataSent = {
      CustomerId: 'malmostad',
      ErrorReport: values,
    };
    const jsonData = JSON.stringify(dataSent);
    await AzureFunction.post(
      '/api/SendCaseAsync?code=ZZJGO4mTg52t2oBkWB7xBlhg4/282cCM2ODNYPVboM25WF6qli7XhA==',
      jsonData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    )
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          //if status code is 200 show success snackbar
          console.log(res.status);
          setSuccess(true);
          setOpen(true);
        } else {
          //if status code is bad show error snackbar
          console.log(res.status);
          setSuccess(false);
          setOpen(true);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setSuccess(false);
        setOpen(true);
      });
    onReset();
  };
  //reset form when button click or sent form success
  const onReset = () => {
    setValues(initialFValues);
    setOptionAdress(null);
    setDropdownValue([]);
    setImage('Ingen bild vald');
  };
  //close for snackbar
  const handleClose = () => {
    setOpen(false);
  };

  const itemRows = [];
  for (let item of dropdownValue) {
    for (let costItems of item.CostCenters) {
      const row = (
        <MenuItem key={costItems} value={costItems}>
          {costItems}
        </MenuItem>
      );

      itemRows.push(row);
    }
  }
  //filter away duplicate values for adressess so dropdown only show an adress one time
  var uniqueAdressess = facilities
    .map((p) => p.FacilityAdress)
    .filter(
      (FacilityAdress, index, arr) => arr.indexOf(FacilityAdress) == index
    );
  return (
    <div>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start">
        <ThemeProvider theme={theme}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              gutterBottom
              component="div"
              sx={{ marginLeft: 1, marginTop: 1 }}
              align="left"
              style={{ color: '#314F6F', fontFamily: 'sans-serif' }}>
              Fyll i din felanmälan
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              gutterBottom
              component="div"
              align="left"
              sx={{ marginLeft: 1, marginTop: 1 }}
              style={{ fontFamily: 'sans-serif' }}>
              Du kan fylla i ett ärende per anmälan. <br />* = obligatoriskt
            </Typography>
          </Grid>
        </ThemeProvider>
      </Grid>
      <Box
        component="form"
        display="flex"
        alignItems="center"
        justifyContent="center"
        autoComplete="off"
        flexWrap="wrap"
        onSubmit={handleSubmit}
        onReset={onReset}>
        <Autocomplete
          options={uniqueAdressess}
          value={optionAdress}
          clearOnBlur={true}
          onChange={(event, newValue) => {
            onChangeAdress(newValue);
            setOptionAdress(newValue);
          }}
          fullWidth
          isOptionEqualToValue={(option, value) => option === value}
          disablePortal
          sx={{
            width: '80%',
            mt: 2,
            mb: 3,
          }}
          renderInput={(params) => (
            <TextField {...params} label="Adress" required />
          )}
        />
        <TextField
          required
          value={values.FacilityName}
          label="Verksamhetsnamn"
          onChange={handleChange('FacilityName')}
          sx={{
            width: '80%',
            mb: 3,
          }}
          SelectProps={{
            MenuProps: MenuProps,
          }}
          select>
          {dropdownValue.map((dropdown) => (
            <MenuItem key={dropdown.RowKey} value={dropdown.FacilityName}>
              {dropdown.FacilityName}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          required
          value={values.CostCenters}
          label="Kostnadsställen"
          onChange={handleChange('CostCenters')}
          sx={{
            width: '80%',
            mb: 3,
          }}
          SelectProps={{
            MenuProps: MenuProps,
          }}
          select>
          {itemRows}
        </TextField>
        <TextField
          required
          value={values.Floor}
          label="Våningsplan"
          onChange={handleChange('Floor')}
          sx={{
            width: '80%',
            mb: 3,
          }}
          SelectProps={{
            MenuProps: MenuProps,
          }}
          select>
          <MenuItem value="1">1</MenuItem>
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="3">3</MenuItem>
          <MenuItem value="4">4</MenuItem>
          <MenuItem value="5">5</MenuItem>
          <MenuItem value="6">6</MenuItem>
          <MenuItem value="7">7</MenuItem>
          <MenuItem value="Källare">Källare</MenuItem>
          <MenuItem value="Vind">Vind</MenuItem>
        </TextField>
        <TextField
          required
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          id="outlined-required"
          label="Portkod"
          value={values.EntranceCode}
          sx={{
            width: '80%',
            mb: 3,
          }}
          onChange={handleChange('EntranceCode')}
        />
        <TextField
          required
          label="Lokal/rumsnr"
          value={values.RoomNumber}
          sx={{
            width: '80%',
            mb: 3,
          }}
          onChange={handleChange('RoomNumber')}
        />
        <TextField
          required
          value={values.IsApartment}
          label="Är ärendet utanför lägenhet?"
          onChange={handleChange('IsApartment')}
          sx={{
            width: '80%',
            mb: 3,
          }}
          select>
          <MenuItem value="Ja">Ja</MenuItem>
          <MenuItem value="Nej">Nej</MenuItem>
        </TextField>

        <TextField
          required
          label="Kategorier"
          value={values.Errors}
          onChange={handleErrors('Errors')}
          SelectProps={{
            MenuProps: MenuProps,
            /*multiple: true*/
          }}
          select
          sx={{
            width: '80%',
            mb: 3,
          }}>
          {categories.sort().map((Errors) => (
            <MenuItem key={Errors} value={Errors}>
              {Errors}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          required
          label="Titel på ärende"
          value={values.Title}
          sx={{
            width: '80%',
            mb: 3,
          }}
          onChange={handleChange('Title')}
        />
        <TextField
          required
          multiline
          maxRows={10}
          label="Beskrivning"
          value={values.Description}
          sx={{
            width: '80%',
            mb: 3,
          }}
          onChange={handleChange('Description')}
        />
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          mr={20}
          ml={20}
          spacing={2}
          flexGrow={1}>
          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              id="contained-button-file"
              type="file"
              capture="environment"
              onChange={onChange}
            />
            <Button
              variant="contained"
              component="span"
              endIcon={<PhotoCamera />}
              style={{ backgroundColor: '#314F6F' }}>
              Bild
            </Button>
          </label>
          <p style={{ marginLeft: 15, flexShrink: 1, flexGrow: 1 }}>{image}</p>{' '}
          {/*text field that shows the selected pictures filename */}
        </Stack>

        <TextField
          required
          label="Namn"
          defaultValue={accountInfo.name}
          sx={{
            width: '80%',
            mb: 3,
            mt: 3,
          }}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          required
          label="Telefon"
          value={values.PhoneNumber}
          sx={{
            width: '80%',
            mb: 3,
          }}
          onChange={handleChange('PhoneNumber')}
        />
        <TextField
          required
          label="Epost"
          //value={values.Email}
          defaultValue={accountInfo.username}
          sx={{
            width: '80%',
            mb: 3,
          }}
          InputProps={{
            readOnly: true,
          }}
        />
        <Stack direction="row" mr={20} ml={20} spacing={2}>
          <Button
            type="reset"
            variant="contained"
            endIcon={<RestartAltIcon />}
            style={{ backgroundColor: '#314F6F' }}>
            Rensa
          </Button>
          <Button
            type="submit"
            variant="contained"
            endIcon={<SendIcon />}
            style={{ backgroundColor: '#314F6F' }}>
            Skicka
          </Button>
        </Stack>
      </Box>
      {success === true && (
        <div>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: '100%' }}>
              Felanmälan skickad!
            </Alert>
          </Snackbar>
        </div>
      )}
      {success === false && (
        <div>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: '100%' }}>
              Felanmälan misslyckades. Vänligen försök senare
            </Alert>
          </Snackbar>
        </div>
      )}
    </div>
  );
};
export default CreateForm;
