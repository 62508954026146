import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import CssBaseline from '@mui/material/CssBaseline';
import HomeIcon from '@mui/icons-material/Home';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ViewListIcon from '@mui/icons-material/ViewList';
import Logout from '@mui/icons-material/Logout';
import { ClassNames } from '@emotion/react';
import Paper from '@mui/material/Paper';
import { useMsal } from '@azure/msal-react';

export default function Menu() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const { instance } = useMsal();

  function handleLogout() {
    instance.logout();
    navigate('/');
  }

  return (
    <Box sx={{ paddingTop: 10 }}>
      <CssBaseline />
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
          'z-index': 1000,
        }}>
        <BottomNavigation
          showLabels
          value={value}
          className={ClassNames.root}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          style={{ backgroundColor: '#314F6F', opacity: 1 }}
          elevation={3}>
          <BottomNavigationAction
            label="Hem"
            style={{ color: '#FFFFFF' }}
            icon={<HomeIcon />}
            component={RouterLink}
            to="/"
          />
          <BottomNavigationAction
            label="Felanmälan"
            style={{ color: '#FFFFFF' }}
            icon={<PostAddIcon />}
            component={RouterLink}
            to="/create/report"
          />
          <BottomNavigationAction
            label="Status"
            style={{ color: '#FFFFFF' }}
            icon={<ViewListIcon />}
            component={RouterLink}
            to="/view/reports"
          />
          <BottomNavigationAction
            label="Logga ut"
            style={{ color: '#FFFFFF' }}
            icon={<Logout />}
            component={RouterLink}
            to="#"
            onClick={() => handleLogout()}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
