export const msalConfig = {
  auth: {
    clientId: 'e08d8556-a409-42c1-9411-7f0f13e1c1d1', //Application ID
    authority:
      'https://login.microsoftonline.com/3e85874b-2d85-4c47-ab4c-d44ae5644292', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: 'https://malmostad.issuereporting.ibinder.com/',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read', 'openid'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'Enter_the_Graph_Endpoint_Here/v1.0/me',
};
