import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useLocation } from 'react-router-dom';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton aria-label="Kommentarer" {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
//Used to map status numbers to correct name in iBinder platform
const dict = {
  0: 'Inskickat',

  1: 'Pågående',

  2: 'Pågående',

  3: 'Avslutat',
};

const ReportDetails = ({ accountInfo }) => {
  const [expanded, setExpanded] = React.useState(false);
  const location = useLocation();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const comments = location.state.Events.map((comment) => {
    return (
      <Card>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              {comment.CreatedBy.Name.charAt(0)}
            </Avatar>
          }
          title={comment.CreatedBy.Name}
          subheader={comment.CreatedDate} //
        />
        <CardContent>
          <List>
            <ListItem>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {comment.Description}
              </Typography>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    );
  });
  return (
    <Card>
      <CardHeader
        title={location.state.Title}
        subheader={dict[location.state.Status]}
      />
      <CardContent>
        <Typography>
          Ärendenummer: {location.state.Id} <br />
          Adress: {location.state.FacilityAdress} <br />
          Beskrivning: {location.state.Description} <br />
          Lokal/rumsnummer: {location.state.RoomNumber} <br />
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more">
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {comments}
      </Collapse>
    </Card>
  );
};

export default ReportDetails;
