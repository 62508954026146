import React, { useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Header from './Header';
import CreateForm from './CreateForm';
import SentForms from './SentForms';
import HomePage from './HomePage';
import Menu from './Menu';
import ReportDetails from './ReportDetails';
import AzureFunction from './APIs/AzureFunction';
import {
  useMsal,
  AuthenticatedTemplate,
  useMsalAuthentication,
  useIsAuthenticated,
} from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import {
  InteractionType,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';

const App = () => {
  const [facilityData, setFacilityData] = useState([]);
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [accessToken, setAccessToken] = useState('');

  const { login } = useMsalAuthentication(
    InteractionType.Redirect,
    loginRequest
  );

  const renewTokenIfNeeded = async () => {
    try {
      const response = await instance.acquireTokenSilent({
        account: accounts[0],
        scopes: loginRequest.scopes,
      });

      if (response.idToken) {
        setAccessToken(response.idToken);
        localStorage.setItem('idtoken', response.idToken);
      }
    } catch (error) {
      console.error('Error acquiring token:', error);
      // Handle token acquisition error
    }
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('idtoken');
    if (storedToken) {
      setAccessToken(storedToken);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && accessToken) {
      const fetchData = async () => {
        try {
          const response = await AzureFunction.get(
            '/api/RetrieveFacilitiesDetailsAsync?code=HNcbQDb844hcIPHC6Ma8xO1lOtNam68SKNTYSJA1aFCEhYg7TxYdBA==',
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + accessToken,
              },
              params: { customerId: 'malmostad' },
            }
          );
          setFacilityData(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle error in fetching data
        }
      };
      fetchData();
    }
  }, [isAuthenticated, accessToken]);

  useEffect(() => {
    if (isAuthenticated) {
      renewTokenIfNeeded();
    }
  }, [isAuthenticated]);

  return (
    <div>
      <Header />
      <BrowserRouter>
        <AuthenticatedTemplate>
          <Routes>
            <Route
              path="/"
              element={
                <HomePage
                  facilities={facilityData}
                  accountInfo={accounts[0]}
                  token={accessToken}
                />
              }
            />
            <Route
              path="/create/report"
              element={
                <CreateForm
                  facilities={facilityData}
                  accountInfo={accounts[0]}
                  token={accessToken}
                />
              }
            />
            <Route
              path="/view/reports"
              element={
                <SentForms accountInfo={accounts[0]} token={accessToken} />
              }
            />
            <Route
              path="/view/reports/details"
              element={
                <ReportDetails accountInfo={accounts[0]} token={accessToken} />
              }
            />
          </Routes>
        </AuthenticatedTemplate>
        <Menu />
      </BrowserRouter>
    </div>
  );
};

export default App;
