import React from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Logo from '../images/logo.png';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const Header = () => {
    return (
        <Box style={{ flexGrow: 1 }}>
            <AppBar position="fixed" elevation={3} style={{ backgroundColor: '#314F6F' }}>
                <Grid
                    justify="space-between"
                    container
                    spacing={24}
                >
                    <Grid item>
                        <Container maxWidth="xs" >
                            <Toolbar >
                                <img src={Logo} style={{ maxWidth: 150 }} />
                            </Toolbar>
                        </Container>
                    </Grid>
                </Grid>
            </AppBar>
            <Toolbar />
        </Box>
    )
}

export default Header;